import React from 'react';

// Importing styles
import styles from "./projects.module.scss";

const Projects = () => {
  return (
    <div className={`${styles.projects}`}>
        Projects
    </div>
  )
}

export default Projects