import ru from './ru.js';
import en from './en.js';
import uz from './uz.js';

export const dictionaryList = { en, ru, uz };

export const languageOptions = {
  en: 'English',
  ru: 'Русская',
  uz: 'Uzb'
};